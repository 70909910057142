import React, { Component } from "react"

import Header from "../components/Header"
import TitleBanner from "../components/TitleBanner"
import Footer from "../components/Footer"
import Docs from "../components/Documentation"

export default class Home extends Component {

  render() {

    return (
      <div>
        <Header />
        <main className="home initialized-header">
          <div>
            <TitleBanner
              title="Fullstack UI Kit Documentation"
              subtitle="Lorem Ipsum Dolor Sit Amet."
            />
            <Docs />
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}
