import React from "react"
import { Link } from "gatsby"

function Documentation(props) {
  const cardStyle = {
    width: '300px',
    margin: '15px 0'
  }

  return (
    <div>
      <div className="container">
        <p className="text-left">Card With Image Top, Title, Subtitle, Text and Button</p>
        <div className="sample-card" style={cardStyle}>
          <div className="card card-default">
            <img className="card-img-top img-fluid" src="https://placehold.co/300x200" alt="Card Placeholder"/>
            <div className="card-body">
              <h3 className="card-title">Title</h3>
              <h5 className="card-subtitle">Subtitle</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <Link to="#" className="btn btn-blue">Button</Link>
            </div>
          </div>
        </div>

        <p className="text-left">Card With Image Bottom, Title, Subtitle, Text and Button</p>
        <div className="sample-card" style={cardStyle}>
          <div className="card card-default">
            <img className="card-img-bottom img-fluid" src="https://placehold.co/300x200" alt="Card Placeholder"/>
            <div className="card-body">
              <h3 className="card-title">Title</h3>
              <h5 className="card-subtitle">Subtitle</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <Link to="#" className="btn btn-blue">Button</Link>
            </div>
          </div>
        </div>

        <p className="text-left">Card Centered</p>
        <div className="sample-card" style={cardStyle}>
          <div className="card card-centered card-default">
            <img className="card-img-bottom img-fluid" src="https://placehold.co/300x200" alt="Card Placeholder"/>
            <div className="card-body">
              <h3 className="card-title">Title</h3>
              <h5 className="card-subtitle">Subtitle</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <Link to="#" className="btn btn-blue">Button</Link>
            </div>
          </div>
        </div>

        <p className="text-left">Nav</p>
        <div className="sample-nav">
          <ul className="nav unstyled">
            <li className="nav-list">
              <Link className="nav-link" to="#">Home</Link>
            </li>
            <li className="nav-list">
              <Link className="nav-link" to="#">About</Link>
            </li>
            <li className="nav-list">
              <Link className="nav-link" to="#">Careers</Link>
            </li>
            <button className="btn btn-outline-blue">button</button>
          </ul>
        </div>

        <p className="text-left">Nav Centered</p>
        <div className="sample-nav">
          <ul className="nav nav-centered unstyled">
            <li className="nav-list">
              <Link className="nav-link" to="#">Home</Link>
            </li>
            <li className="nav-list">
              <Link className="nav-link" to="#">About</Link>
            </li>
            <li className="nav-list">
              <Link className="nav-link" to="#">Careers</Link>
            </li>
            <button className="btn btn-outline-blue">button</button>
          </ul>
        </div>

        <p className="text-left">Nav Flex End</p>
        <div className="sample-nav">
          <ul className="nav nav-right unstyled">
            <li className="nav-list">
              <Link className="nav-link" to="#">Home</Link>
            </li>
            <li className="nav-list">
              <Link className="nav-link" to="#">About</Link>
            </li>
            <li className="nav-list">
              <Link className="nav-link" to="#">Careers</Link>
            </li>
            <button className="btn btn-outline-blue">button</button>
          </ul>
        </div>

        <p className="text-left">Navbar</p>
        <div className="sample-navbar">
          <nav className="navbar">
            <Link to="#" className="navbar-brand">
              <img className="" src="https://placehold.co/50x50?text=Logo" alt="Navbar Logo"/>
            </Link>
            <div className="navbar-collapse">
              <ul className="navbar-nav navbar-nav-centered unstyled">
                <li className="navbar-nav-list">
                  <Link className="navbar-nav-link" to="#">Home</Link>
                </li>
                <li className="navbar-nav-list">
                  <Link className="navbar-nav-link" to="#">About</Link>
                </li>
                <li className="navbar-nav-list">
                  <Link className="navbar-nav-link" to="#">Careers</Link>
                </li>
                <button className="btn btn-outline-blue">button</button>
              </ul>
            </div>
          </nav>
        </div>

        <p className="text-left">Sample List</p>
        <ul className="list">
          <li className="list-item">Sample 1</li>
          <li className="list-item">Sample 2</li>
          <li className="list-item">Sample 3</li>
        </ul>
        
        <p className="text-left">Double Column</p>
        <div className="column-holder">
          <div className="column-content content-centered">
            <h1 className="content-title">Text</h1>
            <p className="content-text">Some quick example text to build on the card title and make up the bulk of the card's content</p>
            <button className="btn btn-outline-blue">button</button>
          </div>
          <div className="column-image">
            <img className="img img-fluid" src="https://placehold.co/500x300?text=Logo" alt="column placeholder"></img>
          </div>
        </div>

        <p className="text-left">Modal</p>
        <a href="#openModal">Open Modal</a>
        <div id="openModal" className="modal-dialog">
          <div>
            <div className="modal-header">
              <a href="#close" title="Close" className="close"><span className="fa fa-times"></span></a>
            </div>
            <div className="modal-body">
              <h2>Modal Box</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div>
          <h2 className="text-left">Tooltips</h2>
          <ul className="list tooltips-holder">
            <li className="list-item">
              <span className="list-link">Sample 1</span>
              <span className="tooltip">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
            </li>
            <li className="list-item">
              <span className="list-link">Sample 2</span>
              <span className="tooltip">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
            </li>
            <li className="list-item">
              <span className="list-link">Sample 3</span>
              <span className="tooltip">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Documentation
